/* ------------------------------------------------------------ *\
	# (max-width: 1440px)
\* ------------------------------------------------------------ */
@media (--breakpoint-desktop-big) {
	.slider-primary .content { width: 600px; }
	.slider-primary-nav .slide .content { padding: 30px 45px 0px; }
}

/* ------------------------------------------------------------ *\
	# (max-width: 1260px)
\* ------------------------------------------------------------ */
@media (--breakpoint-desktop) {
	.wrapper { width: auto; padding: 0 20px; }

	.nav-primary,
	.header-btns .btn-heighlight,
	.header-btns .btn-secondary,
	.nav-lang { display: none; }
	.btn-menu-wrap { display: block; }
	.btn-search { font-size: 17px; }

	.menu.is-active { display: block; }

	.form .captcha .form-row { width: 172px; }

	.slider-primary-left { width: 100%; float: none; }
	.slider-primary-wrap { left: 0; width: 100%; height: 506px; margin-left: 0; }
	.accent-slider,
	.slider-primary-left,
	.slider-primary,
	.slider-primary .slide { height: 506px; border: 0; }
	.slider-primary .content { left: 0; }
	.slider-primary .content,
	.slider-primary .slick-current .content { transform: translate3d(0, 0, 0); }
	.slider-primary-right { display: none; }

	.boxes { margin: 0; }
	.boxes-underline { text-align: left; }
	.box-basic,
	.box-media,
	.box-file,
	.box-simple,
	.box-product { width: 31.33%; margin: 0 1% 20px; }
	.main-content .box-basic,
	.main-content .box-media,
	.main-content .box-file,
	.main-content .box-simple,
	.main-content .box-product { width: 48%; margin: 0 1% 20px; }
	.box-product .content { padding: 15px 20px 130px; }
	.box-basic.highlight { width: 64.66%; }
	.box-features { width: 328px; }
	.box-basic.box-featured { width: 100%; height: 450px; overflow: hidden; }
	.boxes-featured-small { padding: 0; }
	.box-light { width: 472px; }

	.accent-video .video-wrap { width: 600px; }

	.footer-top .rich-text { display: none; }
	.footer-top-content .btns { text-align: center; }
	.footer-top .btn-accent { float: none; margin: 14px 0; }

	.block-content { width: 595px; }

	.main-body .main-content { width: 650px; }

	.accent-image-and-text .title { font-size: 31px; }
	.accent-image-and-text .article { max-height: 90px; }
	.accent-quote.accent-quote-text .quote-text { width: 250px; }

	.team-member .article { width: 670px; }
	.team-member.chairman .article { width: 760px; }

	.banner-big { width: 100%; height: 100%; }
	
	.footer-top .footer-top-content { display: block; }
}

/* ------------------------------------------------------------ *\
	# (max-width: 991px)
\* ------------------------------------------------------------ */
@media (--breakpoint-tablet) {
	.main-body .sidebar,
	.main-body .main-content,
	.nav-aside { width: 100%; float: none; padding-bottom: 20px; }

	.title-primary {  padding-bottom: 15px; }

	.breadcrumbs ul { padding: 15px 0; }
	
	.boxes { margin: 0; }
	.main-body .boxes { text-align: left; }
	.box { width: 48%; height: 100%; margin: 0 1% 20px; }
	.box-basic.highlight { width: 100%; height: 100%; }
	.box-basic.highlight .picture { height: auto; }
	.box-features { width: auto; padding-bottom: 20px; }
	.box-features .title { height: auto; padding: 10px; }
	.box-report .authors { padding-bottom: 40px; }
	.box-underline { width: 100%; }

	.slider-primary .content { padding: 0 50px; }
	.slider-primary .slick-current .content { transition-delay: 0s; }
	.slider-primary .title { font-size: 18px; }
	.slider-gallery { margin: 0 auto; }

	.accents .accent-title { font-weight: bold; }
	.accent-video .video-wrap,
	.accent-video .video-list { width: 100%; float: none; }
	.accent-video-camp iframe { height: 450px; }
	.accent-video .video-list { height: 300px; }
	.accent-video .video-list .list { margin-left: 0; }
	.accents .accent-header .btns { margin-left: 13px; }
	.accent-featured .rich-text { width: 100%; }

	.accent-campaigns .col6of12 { width: 100%; float: none; }
	.accent-quote.accent-quote-text .quote-text { width: 100%; }
	.accent-quote .quote { text-align: center; }
	.accent-image-quote .quote { text-align: left; }
	.accent-image-and-text .article { max-height: 100%; }
	.accent-pad .content-pad { margin-bottom: 20px; margin-left: 0; }
	.accent-image-quote .picture { padding-bottom: 20px; }
	.accent-images-text .picture { padding-top: 20px; }
	.accent-files .widget-list li { width: 49%; }
	.accent-slider-camp .slide { height: 450px; }

	.block-aside,
	.block-content { width: 100%; float: none; }
	.block-picture img { width: 100%; }
	.block-multimedia .title { max-width: 100%; float: none; }
	.block-multimedia .btns { float: none; padding-top: 20px; }
	.block-contacts .addresses > .col,
	.block-contacts .contact-form > .col { width: 100%; float: none; padding-bottom: 20px; }
	.block-donation .donation-method .content { float: none; }
	.block-donation .donation-method .content .rich-text { max-width: 100%; }
	.block-donation .donation-method .utils,
	.block-donation .donation-method .form-donate { width: 100%; float: none; padding-top: 20px; }
	.block-donation .donation-method .utils .picture img { width: auto; }

	.widget-list .title { max-width: 100%; }

	.timeline-item .article,
	.timeline-item .picture { width: calc(50% - 20px); }

	.team-member.chairman .article,
	.team-member .article { width: 100%; padding-top: 20px; }

	.footer-middle .cols { margin: 0; font-size: 0; }
	.footer-middle .col3of12 { width: 50%; float: none; display: inline-block; vertical-align: top; padding: 0 0 20px; font-size: 17px; }
}

/* ------------------------------------------------------------ *\
	# (max-width: 767px)
\* ------------------------------------------------------------ */
@media (--breakpoint-tablet-sm) {
	.form .captcha { height: auto; }
	.form .captcha span { width: 100%; float: none; display: block; padding: 19px 0; line-height: 0; }
	.form .captcha .form-row { width: 100%; float: none; }
	.form .col { width: auto; float: none; }
	.block-contacts .addresses .col { padding-bottom: 20px; }
	.block-contacts .addresses .col, 
	.block-contacts .contact-form .col { width: auto; float: none; }
	.block-cart .cols { margin: 0; }
	.block-cart .col { float: none; padding: 0; }
	.block-product-details .col { width: 100%; }

	.main-head { padding-bottom: 10px; }

	.accent-video .video-wrap { height: 100%; }
	.accent-video .video-wrap iframe,
	.accent-video-camp iframe { height: 250px; }

	.slider-primary-left { width: 768px; }
	.slider-primary-wrap { width: 768px; height: 379px; }
	.slider-primary .slide { height: 379px; }
	.slider-primary .content { width: 350px; }
	.slider-primary .btns { display: none; }
	.slider-gallery { width: 100%; height: auto; }

	.accent-campaigns { padding: 10px 0; }
	.accent-campaigns .title,
	.accent-featured-image .title-primary { font-size: 24px; }
	.accent-campaigns.accent-featured .title { font-size: 32px; }
	.accent-campaigns .widget-list .title { font-size: 18px; }
	.accent-slider-camp .slide { height: 250px; }
	.accent-slider-camp .slide .article { margin: 0; max-height: 132px; overflow: hidden; }
	.accent-pad .content-pad { margin-top: -70px; padding: 20px; }
	.accent-campaigns.accent-featured .content { padding-top: 30px; }
	.accent-image-and-text .content { padding: 20px; }
	.accent-files .widget-list li { width: 100%; }
	.accent-quote .quote-text,
	.accent-quote.accent-quote-text .quote-text { width: 240px; }
	.accent-quote .quote { padding: 65px 15px; text-align: left; }
	.accent-image-quote .quote { padding: 14px 55px; }
	.article blockquote .quote-text { margin-left: 10px; }
	.accent-quote .icon-svg,
	.article blockquote:before { width: 41px; height: 31px; }
	.article blockquote:before { background-size: 41px;}
	.accent-slider-camp .slide .article { top: 0; bottom: auto; max-height: 100%; height: 100%; line-height: 1.2; }
	.accent-slider-camp .slider-landing .arrows { width: 15px; }
	.accent-slider-camp .slider-landing .arrow-left-land { left: 2px; }
	.accent-slider-camp .slider-landing .arrow-right-land { right: 2px; }
	.accent-images-text .picture { padding-bottom: 0; }
	.accent-featured-image .date { display: block; padding-top: 10px; padding-left: 0; }
	
	.box { width: 100%; height: auto; margin: 0 0 20px; }
	.main-content .box-basic, .main-content .box-media, .main-content .box-file, .main-content .box-simple, .main-content .box-product { width: auto; }
	.box-basic.box-featured { height: 100%; }
	.box-featured .content .title { font-size: 40px; }
	.box-featured .content .rich-text { font-size: 16px; line-height: 20; }
	.box-featured-small { width: 100%; }
	.box-light .picture { background: #fff; padding: 20px 0; }
	.box-light .title { height: auto; }
	.accent-related .box-media { width: 100%; }

	.block-donation .donation-method .utils,
	.block-donation .donation-method .form-donate .btns { text-align: center; }
	.block-donation .btns-donate { padding-bottom: 10px; }

	.timeline-item .article, 
	.timeline-item .picture { width: 100%; padding-bottom: 20px; }
	
	.team-member { padding: 0; }
	.team-member .picture { float: none; padding-bottom: 20px; }
	.team-member .title { font-size: 21px; }
	.team-member .subtitle { font-size: 18px; }

	.component-btns { padding: 30px 10px; }
	.component-btns .btn-back { margin-right: 10px; }

	.paging .next a, 
	.paging .prev a { display: none; }

	.pad-basic { padding: 20px 10px; }

	.accordion-item .accordion-head { padding: 10px 20px; }
	.accordion-item .accordion-body { padding: 0 25px 20px; }
	.accordion-item .accordion-head .title { font-size: 18px; }
	
	.widgets .link-map { padding-top: 15px; }

	.banner-wide { width: 100%; }

	.footer-middle .col3of12:last-child { width: 100%; }
}

/* ------------------------------------------------------------ *\
	# (max-width: 479px)
\* ------------------------------------------------------------ */
@media (--breakpoint-mobile) {
	.header .logo { width: 140px; margin: 30px 0px 24px 0; }
	.btn-basic { min-width: 125px; }

	.title-primary { font-size: 36px; }

	.choose-group .choose { display: block; padding-right: 0; padding-bottom: 10px; }

	.slider-primary .content { width: 300px; padding: 0 20px; }
	.accent-slider,
	.slider-primary-wrap, 
	.slider-primary-left,
	.slider-primary,
	.slider-primary .slide { height: 400px; }

	.table-stripped thead { display: none; }
	.table-stripped tbody td { display: block; }
	.table-stripped tbody td .label { display: block; }
	.table-cart thead { display: none; }
	.table-cart tbody td { display: block; }
	.table-cart .field-spinner { max-width: 100px; margin-left: -10px; }
	.table-cart tfoot tr { display: block; margin-top: 10px; border-top: 2px solid #15b6cd; }
	.table-cart tfoot .col-all { padding-top: 10px; }
	.table-cart thead .col-picture,
	.table-cart tfoot .col-picture,
	.table-cart thead .col-description,
	.table-cart tfoot .col-description { display: none; }

	.accent .accent-header .accent-title { float: none; padding-bottom: 0; }
	.accent .accent-header .btns { float: none; margin-left: 0; padding-bottom: 20px; }
	.accent .accent-header .btn-link { padding-top: 0; }
	.accent-video ul .list-content { max-width: 175px; }

	.box-featured .content,
	.box-featured .btns { margin: 0 15px;  }
	.box-featured .content .title { font-size: 32px; }

	.product-view-actions { margin-bottom: 20px; }
	
	.timeline-item .title { font-size: 54px; }
	.widget-list .title { max-width: 160px; }
	.accordion-item .accordion-head .icon-pad { display: none; }
	.paging ul li.selected { padding: 0 8px; }
	.component-btns .btns .btn-basic:last-child { float: right; }
	.main-head .rich-text-subtitle { padding-bottom: 0; }

	.team-member.chairman .article, 
	.team-member .article { padding-top: 0; }
	.team-member .title {padding-bottom: 10px; }

	
	.table-cart td { display: block; }
	.table-cart .col-picture img { width: auto; }
	.form-cart-checkout .field-spinner input { width: 100%; }
	.table-cart tfoot .col-all { border-top: 0; }

	.footer-bottom { height: 100%; }
	.copy { float: none; }
	.trademark { float: none; padding-top: 20px; }
}

/* ------------------------------------------------------------ *\
	# (max-width: 479px)
\* ------------------------------------------------------------ */
@media (--breakpoint-mobile-sm) { 
	.accent-quote .quote-text,
	.accent-quote.accent-quote-text .quote-text { width: 170px; }
	.footer-middle .col3of12 { width: 100%; }
}