/* I. Plugins
=============================================================== */
@import "plugins/slick.css";
@import "plugins/toastr.css";
@import "plugins/balloon.css";
@import "plugins/select2.min.css";
@import "plugins/jquery.fancybox.min.css";
@import "plugins/datepicker.css";
@import "plugins/jquery.bootstrap-touchspin.css";

/* II. Generic
=============================================================== */
@import 'partials/_settings.css';
@import 'partials/_generic.css';

/* III. Regions
=============================================================== */
@import 'partials/_regions.css';

/* IV. Modules
=============================================================== */
@import 'partials/_modules.css';

/* IV. Responsive
=============================================================== */
@import 'partials/_responsive.css';