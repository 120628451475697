
 /* ------------------------------------------------------------ *\
	#icons
\* ------------------------------------------------------------ */
.icon { display: inline-block; vertical-align: top; transition: all 0.3s; }
.icon-pad { width: 45px; height: 45px; display: inline-block; vertical-align: middle; border-radius: 50px; background: var(--color-primary); }
.icon-pad .icon-svg { width: 25px; height: 25px; padding: 10px; fill: #fff; } 
.icon-pad-light { background: #fff; }
.icon-pad-light .icon-svg { fill: var(--color-primary); } 

/* ------------------------------------------------------------ *\
	#btns
\* ------------------------------------------------------------ */
.btns { font-size: 0; line-height: 0; }
.btn { position: relative; box-sizing: border-box; display: inline-block; vertical-align: top; background-color: transparent; border: 0; cursor: pointer; appearance: none; transition: all 0.3s; }
.btn:hover { text-decoration: none;	}
.btn-basic { min-width: 140px; height: 30px; padding: 0 20px; color: #fff; border-radius: 50px; font-size: 14px; font-weight: bold; line-height: 32px; text-align: center; text-transform: uppercase; }

.btn-primary { background: var(--color-primary); }
.btn-primary:focus,
.btn-primary:hover, 
.btn-primary.is-selected { background: var(--color-accent); }

.btn-secondary { background: var(--color-secondary); }
.btn-secondary:hover { background: var(--color-primary); }

.btn-heighlight { background: var(--color-accent); }
.btn-heighlight:hover { background: var(--color-primary); }

.btn-circle { width: 45px; height: 45px; background: var(--color-neutral-lt); border-radius: 50px; }
.btn-circle:hover { background: var(--color-primary); }
.btn-circle .icon-svg { width: 25px; height: 25px; padding: 10px; fill: var(--color-primary); }
.btn-circle:hover .icon-svg { fill: #fff; }

.btn-pad { width: 30px; height: 30px; display: block; background: var(--color-neutral-dk); border-radius: 50px; }
.btn-pad .icon-svg { width: 20px; height: 20px; padding: 5px; fill: #fff; }

.btn-newsletter { position: absolute; top: 0; right: 0; width: 35px; height: 35px; background: var(--color-primary); }
.btn-newsletter:before { content: ''; position: absolute; top: 0; left: 0; display: block; margin: 14px 12px; padding: 3px; border: solid var(--color-text); border-width: 0 3px 3px 0; border-radius: 2px; border-color: #fff; pointer-events: none; -webkit-transform: rotate(-45deg); transform: rotate(-45deg); transition: all 0.3s; }

.btn-back { background: #fff; color: var(--color-primary); }
.btn-back:hover { background: var(--color-primary); color: #fff; }

.btn-link { padding-top: 20px; font-size: 17px; line-height: 16px; display: inline-block; }

.btn-article { height: 40px; padding: 6px 10px; background: var(--color-primary); font-size: 18px; text-transform: inherit; }
.btn-article:hover { background: var(--color-accent); }
.btn-article span { padding: 0 10px; }
.btn-article .icon-svg { width: 30px; height: 30px; fill: #fff; }

.btn-landing { padding: 15px; background: var(--color-primary); color:#fff; font-size: 16px; text-transform: uppercase; line-height: 18px; letter-spacing: 1px; }
.btn-landing:hover { background: var(--color-accent); }

.btn-remove:before { content: 'x'; color: var(--error); font-size: 21px; }

.btn-menu-wrap { display: none; width: 50px; height: 50px; line-height: 50px; margin: 20px 0; text-align: center; transition: all .3s; cursor: pointer; }
.btn-menu-wrap .btn-menu { position: relative;  overflow: hidden; display: inline-block; vertical-align: middle; width: 35px; height: 25px; cursor: pointer; }
.btn-menu-wrap .btn-menu:after, 
.btn-menu-wrap .btn-menu:before, 
.btn-menu-wrap .btn-menu span { content: ""; position: absolute; left: 0; top: 0; display: block; width: 100%; height: 2px; background: #fff; transition: all .3s; backface-visibility: hidden; transform: translateZ(0); }
.btn-menu-wrap .btn-menu span { top: 50%; margin-top: -1px; transition: all .3s; }
.btn-menu-wrap.is-active .btn-menu span { display: none; }
.btn-menu-wrap .btn-menu:before { transform-origin: left top; }
.btn-menu-wrap.is-active .btn-menu:before { transform: translate3d(5px,0,0) rotate(42deg); }
.btn-menu-wrap .btn-menu:after { top: auto; bottom: 0; }
.btn-menu-wrap.is-active .btn-menu:after { transform: translate3d(0,-11px,0) rotate(-42deg); }

/* ------------------------------------------------------------ *\
	#nav
\* ------------------------------------------------------------ */
.nav-primary { float: left; }
.nav-primary li { float: left; padding: 34px 10px; font-size: 17px; font-weight: bold; text-transform: uppercase; }
.nav-primary a { color: var(--color-text); }
.nav-primary li:hover a,
.nav-primary li.selected a { color: var(--color-primary); }

.nav-lang { position: relative; float: right; margin-top: 30px; }
.nav-lang li:hover > a { background: var(--color-accent); }
.nav-lang a.is-active { background: var(--color-accent); }
.nav-lang a { width: 30px; height: 30px; display: block; color: #fff; background: var(--color-primary); border-radius: 50px; font-size: 15px; text-align: center; line-height: 31px; }
.nav-lang .nav-lang-dropdown { position: absolute; top: 100%; left: 0; margin-top: 5px; opacity: 0; visibility: hidden; transition: all .3s; }
.nav-lang .is-active { opacity: 1; visibility: visible; }

.nav-aside { width: 275px; float: left; margin-right: 30px; }
.nav-aside .btn-primary { width: 100%; height: 50px; margin-bottom: 10px; font-size: 20px; line-height: 50px; }

.menu { position: absolute; top: 90px; right: 0; width: 250px; padding: 20px; background: var(--color-primary); transition: all .3s; opacity: 0; visibility: hidden; transform: translate3d(50%,0,0); }
.menu.is-active { opacity: 1; visibility: visible; transform: translateZ(0); display: none; }
.menu-top { padding-bottom: 10px; border-bottom: 2px solid #fff; }
.menu li a { display: block; padding: 10px; color: #fff; text-transform: uppercase; }
.menu li a:hover { background: var(--color-accent); }
.menu-bottom { padding-top: 20px; }
.menu-bottom a { display: inline-block; padding: 0 10px; color: #fff; }
/* ------------------------------------------------------------ *\
	#breadcrumbs
\* ------------------------------------------------------------ */
.breadcrumbs ul { padding: 20px 0; }
.breadcrumbs ul li { display: inline; font-size: 14px; line-height: 16px; color: var(--color-neutral-dk); }
.breadcrumbs ul li a { color: inherit; }
.breadcrumbs ul li+li:before { content: '→'; display: inline-block; vertical-align: sub; margin: 0 5px; font-size: 23px; color: var(--color-neutral-dk); }
.breadcrumbs ul li.selected { color: var(--color-primary); }

/* ------------------------------------------------------------ *\
	#article
\* ------------------------------------------------------------ */
.article h1,
.article h2,
.article h3,
.article h4,
.article h5,
.article h6 { margin-bottom: 10px; color: inherit; }

.article:after { @apply --clearfix; }

.article { font-size: 17px; line-height: 22px; }
.article * { max-width: 100%; }
.article hr { display: block; border: none; width: 100%; height: 1px; margin: 10px 0; background: #d9d9d9; }
.article a:not(.btn-article):not(.btn-primary) { position: relative; color: var(--color-link); text-decoration: underline; transition: all 0.3s; }
.article a:hover { text-decoration: none; }

.article p { padding-bottom: 15px; }
.article ul { list-style: none; padding-bottom: 10px; }
.article ul	li { position: relative; padding: 0 0 5px 23px; }
.article ul	li:before { content: '•'; position: absolute; top: 0; left: 0; color: var(--color-primary); font-size: 32px; line-height: inherit; }
.article ol { list-style-position: inside; padding-bottom: 10px; }
.article ol li { padding: 0 0 5px 0; }
.article blockquote { position: relative; z-index: 1; display: block; padding: 20px 0; font-size: 2.4rem; line-height: 30px; color: var(--color-text); }
.article blockquote:before { content: ''; position: absolute; top: 22px; left: 0; width: 100px; height: 75px; background-image: url('../../images/quote.svg'); background-size: 100px; background-repeat: no-repeat; z-index: -1; }
.article blockquote .quote-text { margin-left: 120px; }

.article table { margin-bottom: 10px; }

.article-subtitle { max-width: 900px; margin: 0 auto; text-align: center; }
.article-title { margin-bottom: 10px; color: var(--color-primary); }

.table-basic { width: 100%; margin-bottom: 20px; font-size: var(--font-size-primary); line-height: 2rem; text-align: center; }
.table-basic th,
.table-basic td { border: 1px solid var(--color-neutral-dk); padding: 5px; }
.table-basic th { background: var(--color-neutral-dk); color: #fff; font-weight: normal; }
.table-basic tr:hover td { background: var(--color-neutral-lt); }

.table-grid,
.table-grid thead,
.table-grid tbody,
.table-grid tr,
.table-grid th,
.table-grid td { box-sizing: border-box; }
.table-grid th,
.table-grid td { vertical-align: top; }

.article-404 { padding-top: 50px; text-align: center; }

/* ------------------------------------------------------------ *
    #forms
* ------------------------------------------------------------ */
/* form elements */
legend { display: table; float: left; margin: 0; padding: 0; width: 100%; }
legend + * { clear: both; }
fieldset { border: 0; padding: 0.01em 0 0 0; min-width: 0; }

fieldset,
legend,
.field input,
.field select,
.field textarea { appearance: none; box-sizing: border-box; }
.field select::-ms-expand { display: none; }

.field input,
.field select,
.field textarea { display: block; max-width: 100%; width: 100%; height: 50px; border: 1px solid var(--color-neutral-md); border-radius: 0; padding: 12px 15px; padding-right: 30px; background: #fff; font-size: var(--font-size-primary); line-height: 24px; color: var(--color-text); }
.field textarea { height: 260px; }
.field select { padding: 0 20px; line-height: 48px; user-select: none; cursor: pointer; }
.field-select { position: relative; display: block; max-width: 100%; }
.field-select:before { content: ''; position: absolute; top: 0; right: 10px; z-index: 2; display: block; margin: 17px 0; padding: 3px; border: solid var(--color-text); border-width: 0 3px 3px 0; border-radius: 2px; pointer-events: none; -webkit-transform: rotate(45deg); transform: rotate(45deg); transition: all 0.3s; } 
.field select:focus,
.field input:focus,
.field textarea:focus { border-color: var(--color-primary); }
.field select[disabled],
.field textarea[disabled],
.field input[disabled] { border-color: var(--color-neutral-lt); background: var(--color-neutral-lt); }

.filer-wrap input[type=file] { opacity: 0; }
.filer-wrap .filer-dummy { position: absolute; top: 0; left: 0; pointer-events: none; }
.field input.filer-dummy { background: #fff; border-color: var(--color-neutral-md); }

.choose-group { overflow: hidden; padding: 20px 0; }
.choose-group .choose { display: inline-block; vertical-align: top; padding-right: 68px; }
.choose-group.choose-payment-method { padding: 40px 0 20px; }
.choose { padding-bottom: 10px; line-height: 1; }
.choose:after { content: ' '; display: block; clear: both; }
.choose:last-child { padding-bottom: 0; }

.radio > input,
.check > input { float: left; width: 22px; height: 22px; margin: 0 10px 0 0; opacity: 0; }
.radio > label,
.check > label { font-size: 17px; line-height: 16px; color: #555; vertical-align: sub; user-select: none; }
.radio > label:before,
.check > label:before { content: ''; position: absolute; top: 0; left: 0; display: block; width: 22px; height: 22px; border: 1px solid var(--color-neutral-md); background: #fff; transition: all 0.3s; pointer-events: none; }
.radio > label:after,
.check > label:after { content: ''; position: absolute; top: 5px; left: 5px; width: 12px; height: 12px; background: var(--color-primary); transition: all 0.3s; opacity: 0; visibility: hidden; pointer-events: none; }
.radio > input:checked + label:after,
.check > input:checked + label:after { opacity: 1; visibility: visible; }
.radio > label:before { border-radius: 50%; }
.radio > label:after { border-radius: 50%; }

/* form errors and messages */
.choose,
.field { position: relative; }
.field.error > textarea,
.field.error > select,
.field.error > input { position: relative; border-bottom-color: var(--error); color: var(--error); }
.field.error:after { content: '!'; position: absolute; top: 50%; left: 7px; font-size: 20px; color: var(--error); font-weight: bold; transform: translateY(-50%); }
.choose.error > label { color: var(--error); }
.field.success > textarea,
.field.success > select,
.field.success > input { position: relative; border-bottom-color: var(--success); color: var(--success); }
.field.success:after { content: '✓'; position: absolute; top: 50%; left: 5px; width: 20px; height: 20px; color: var(--success); transform: translateY(-50%); }
.choose.success > label { color: var(--success); }
.form-msg { padding: 10px 0; font-size: 12px; line-height: 16px; color: var(--color-text); }
.form-msg.error { color: var(--error); }
.form-msg.success { color: var(--success); }
.form-hint { padding: 3px 0; font-size: 12px; line-height: 19px; color: var(--color-text); }

/* form types */
.form .form-row { margin-bottom: 20px; }
.form .form-title { margin-bottom: 30px; font-size: 22px; line-height: 1.35; color: var(--color-primary); }
.form .form-highlight,
.form .form-note { margin-bottom: 30px; font-size: var(--font-size-primary); line-height: 1.3; }
.form .form-title + .form-highlight,
.form .form-title + .form-note { margin-top: -20px; }
.form .form-highlight { color: var(--color-secondary); }
.form .label,
.form .form-label { display: block; margin-bottom: 10px; font-size: 14px; line-height: 18px; color: #777; }
.form .form-label.required:after { content: '*'; }
.form .form-label-inline { width: 45px; float: left; margin: 11px 0; color: #fff; font-size: 18px; font-weight: bold; text-transform: uppercase; }
.form .form-hint { padding: 3px 0; font-size: 12px; line-height: 19px; color: #bbb; }
.form .col .btn { height: 50px; display: block; width: 100%; }
.form .col .btn-add { width: auto; }
.form .btn-primary { padding: 10px 40px; color: #fff; font-size: 18px; text-transform: uppercase; line-height: 22px; }
.form .btn-inline { margin-top: 28px; }
.form hr { display: block; border: 0; height: 1px; margin: 15px 0; background: var(--color-neutral-dk); }
.form .form-row + hr { margin-top: 0; }
.form .captcha { position: relative; height: 78px; }
/*@media (max-width: 360px) {
    .form .captcha img,
    .form .captcha > div { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scale(0.9); transform: translate3d(-50%, -50%, 0) scale3d(0.9, 0.9, 1); }  
}*/
.form .form-group { padding: 15px 0; }
.form .form-msg { padding: 10px 0; font-size: 12px; line-height: 16px; color: var(--color-text); }
.form .form-msg.error { color: var(--error); }
.form .form-msg.success { color: var(--success); }

.form .captcha span { width: 90px; float: left; height: 12px; padding: 19px 10px; background: var(--color-primary); text-align: center; }
.form .captcha img { width: auto; }
.form .captcha .form-row { width: 215px; float: left; overflow: hidden; }

.form-basic { max-width: 600px; margin: 40px auto; text-align: center; }
.form-basic .form-row { padding-bottom: 15px; text-align: left; }
.form-basic .btns { padding: 20px 0; }

.form-search form { position: relative; height: 100%; }
.form-search input { height: 70px; padding-right: 100px; padding-left: 40px; background-color: var(--color-primary); border-color: transparent; background-color: transparent; font-size: 18px; color: #fff; }
.form-search input::-webkit-input-placeholder { color: #fff; }
.form-search input:-ms-input-placeholder { color: #fff; }
.form-search input::placeholder { color: #fff; }
.form-search { opacity: 0; visibility: hidden; height: 0; background-color: var(--color-primary); transition-duration: 0.3s; transition-timing-function: ease-in; }
.form-search.is-active { opacity: 1; visibility: visible; height: 70px; transition-duration: 0.3s; transition-timing-function: ease-in; }
.form-search .field { position: relative; }
.form-search .field:before { content: ''; position: absolute; top: 50%; left: 0; width: 24px; height: 24px; background-image: url('../../images/magnifying.svg'); background-size: 24px; background-repeat: no-repeat; transform: translateY(-50%); }
.form-search .btn-secondary { position: absolute; top: 50%; right: 0; min-width: 94px; color: #fff; transform: translateY(-50%); }

.form.form-filter .label { padding-bottom: 5px; font-size: 18px; color: #fff; text-transform: inherit; font-weight: normal; }
.form-filter fieldset { display: block; padding: 20px 20px 0; border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
.form-filter fieldset .field { overflow: hidden; }

.form-filter { overflow: hidden; background: var(--color-primary); margin-bottom: 30px; }
.form-filter fieldset .field:after { content: ''; position: absolute; top: 50%; left: 0; background-image: url('../../images/filter-calendar.svg'); width: 21px; height: 21px; margin-left: 8px; background-size: 21px 21px; background-repeat: no-repeat; transform: translate(0, -50%); }
.form-filter .field.field-select:after { margin: 0 8px; border: 0; background-image: url('../../images/filter-newspaper.svg'); }
.form-filter .field-icon { padding: 0; }
.form-filter fieldset .field.field-select.field-icon:after { display: none; }
.form-filter .filter-datepicker input { width: 260px; float: left; }
.form-filter input,
.form-filter select { padding-left: 40px; border: 0; }

.form-filter-cases select { padding: 0 10px; }
.form-filter-cases .filter-select .field:after { margin: 0 8px; border: 0; background-image: none; }

.form-legal-aid { max-width: 680px; margin: 0 auto 40px; }
.form-legal-aid .col .btn-link { width: auto; height: auto; padding-top: 0; padding-bottom: 20px; }
.form-legal-aid .btn-add { float: left; }
.form-legal-aid .btn-remove { float: right; }
.form-legal-aid .uploads .form-row { display: none; }
.form-legal-aid .uploads .form-row.is-visible { display: block; }
.form-legal-aid .col .btn-remove { display: none; }

.form-cart-checkout fieldset { max-width: 680px; margin: 0 auto 40px; }
.form-cart-checkout .title { font-size: 16px; text-transform: uppercase; border-bottom: 1px solid var(--color-neutral-md); }
.form-cart-checkout .field-spinner input { width: 84px; padding: 0; text-align: center; border: 0; }
.form-cart-checkout .field-spinner .bootstrap-touchspin .input-group-btn-vertical { position: inherit; }
.form-cart-checkout .field-spinner .btn-default { position: absolute;  top: 17px; width: 10px; height: 14px; padding: 0; }
.form-cart-checkout .field-spinner .bootstrap-touchspin-up { right: 10px; top: 15px; }
.form-cart-checkout .field-spinner .bootstrap-touchspin-down { left: 10px; }
.form-cart-checkout .field-spinner .btn-default:before {  content: ''; position: absolute; top: 0; width: 10px; height: 14px; background-repeat: no-repeat; background-size: 10px 14px; }
.form-cart-checkout .field-spinner .bootstrap-touchspin-up:before { right: 0; background-image: url('../../images/cart-arrow-r.svg'); }
.form-cart-checkout .field-spinner .bootstrap-touchspin-down:before { left: 0; background-image: url('../../images/cart-arrow-l.svg'); }
.form-cart-checkout .field textarea { height: 100px; }

.form-donate input,
.form-donate select,
.form-donate textarea { background: #f5f5f5; border-color: #f5f5f5; }
.form-donate .btns { text-align: right; }
.form-donate .btn-primary { border-radius: 20px; }

.form-petition .form-title { padding-top: 30px; color: var(--color-text); font-size: 35px; margin-bottom: 20px; font-weight: normal; }

/* ------------------------------------------------------------ *
    #socials
* ------------------------------------------------------------ */
.socials { position: relative; }
.socials li { padding: 5px; }
.socials-share { position: fixed; top: 50%; left: 0; opacity: 0; visibility: hidden; margin-left: -100px; background: #fff; border: 1px solid var(--color-neutral-md); transition: all .7s ease-in-out; transform: translate3d(0, -50%, 0); z-index: 999;  }
.socials-share.is-active { opacity: 1; visibility: visible; margin-left: 0; }
.socials .icon-svg { width: 22px; height: 22px; padding: 5px; border-radius: 50px; background: var(--color-primary); fill: #fff; transition: all 0.3s; }
.socials .icon-svg:hover { background: var(--color-accent); }

/* ------------------------------------------------------------ *
    #table
* ------------------------------------------------------------ */
.table { width: 100%; }
.table-stripped { width: 100%; border: 1px solid var(--color-neutral-md); }
.table-stripped thead { height: 50px; background: #7d7d7d; }
.table-stripped thead th { padding: 18px 10px; color: #fff; font-size: 14px; font-weight: bold; text-align: left; text-transform: uppercase; line-height: 14px; }

.table-stripped tbody tr { border-bottom:1px solid var(--color-neutral-md); }
.table-stripped tbody tr:nth-child(even) { background: var(--color-neutral-lt); }
.table-stripped tbody tr:nth-child(odd) { background: #fff; }
.table-stripped tbody td { padding: 10px }
.table-stripped tbody td .label { display: none; }
.table-stripped tbody .title:after { content: '>>'; display: inline-block; vertical-align: middle; padding-left: 5px; }

.table-cart td,
.table-cart th { padding: 5px; }
.table-cart .description h6 { font-size: 14px; text-transform: uppercase; }
.table-cart tfoot .col-all { text-transform: uppercase; font-weight: bold; }
.table-cart tfoot .col-all,
.table-cart tfoot .col-price { border-top: 1px solid var(--color-neutral-md); }

/* ------------------------------------------------------------ *
    #sliders
* ------------------------------------------------------------ */
.slider .slide { position: relative; background-size: cover; background-position: center center; background-repeat: no-repeat; }
.slider-landing .slides-btns { position: absolute; top: 50%; left: 0; width: 100%; transform: translate3d(0, -50%, 0); }
.slider-landing .slides-btns .slick-arrow { position: absolute; left: 20px; width: 50px; height: 50px; filter:drop-shadow(0 0 1px #000); }
.slider-landing .slides-btns .next { right: 20px; left: auto; }
.slider-landing .slides-btns img { width: 50px; }

.slider-primary-wrap { position: absolute; top: 0; left: 50%; width: 2000px; z-index: 1; height: 580px; margin-left: -1000px; background: #212121; }
.slider-primary-left { position: relative; z-index: 1; float: left; width: 1360px; height: 580px; }
.slider-primary-right { position: relative; z-index: 2; float: left; width: 640px; height: 480px; padding: 50px 0; }
.slider-primary-right .more-arrow { display: none; position: absolute; bottom: 19px; left: 15px; height: 30px; width: 20px; animation: up-and-down 0.7s infinite; }

.slider-primary { position: relative; overflow: hidden; height: 583px; }
.slider-primary .slide { height: 580px; }
.slider-primary .slide:before { content: ''; position: absolute; right: 0; bottom: 0; width: 100%; height: 50%; background: linear-gradient(to bottom, transparent, #000); }
.slider-primary .content { position: absolute; bottom: 55px; left: 50%; width: 750px; padding: 0 150px; transition: all 0.4s; opacity: 0; transform: translate3d(-50%, 50%, 0); }
.slider-primary .slick-current .content { transition-delay: 0.5s; opacity: 1; transform: translate3d(-50%, 0, 0); }
.slider-primary .title { margin-bottom: 10px; font-size: 30px; }
.slider-primary .title a { color: #fff; }
.slider-primary .date { font-size: 15px; }
.slider-primary .date .icon-svg { width: 20px; height: 20px; }
.slider-primary .btns { position: absolute; right: 0; bottom: 50%; transform: translateY(50%); }
.slider-primary-nav { float: left; overflow: hidden; width: 325px; height: 480px; margin-left: -20px; }
.slider-primary-nav .slides { overflow: hidden; height: 480px; }
.slider-primary-nav .slide { height: 160px; }
.slider-primary-nav .slide .content { position: relative; height: 130px; padding: 30px 35px 0; color: var(--color-neutral-dk); }
.slider-primary-nav .slide .content .title { overflow: hidden; max-height: 84px; font-size: 17px; font-weight: bold; line-height: 21px; }
.slider-primary-nav .slide .content .date { padding-top: 10px; }
.slider-primary-nav .slide .content:before,
.slider-primary-nav .slide .content:after { content: ''; position: absolute; display: none; }
.slider-primary-nav .slide .content:before { top: 0; left: 0; border-width: 80px 18px 80px 0; border-style: solid; border-color: transparent var(--color-neutral-lt) transparent transparent; }
.slider-primary-nav .slide .content:after { top: 0; right: 0; bottom: 0; left: 18px; z-index: -1; background: var(--color-neutral-lt); }
.slider-primary-nav .slide.slick-current .content { color: var(--color-text); }
.slider-primary-nav .slide.slick-current .content:after,
.slider-primary-nav .slide.slick-current .content:before { display: block; }

.slider-related .slides { height: 200px; margin: 0 -5px; }
.slider-related .slide { height: 200px; margin: 0 5px; }
.slider-related .slick-arrow { position: absolute; top: 0; right: 0; }
.slider-related #prev { right: 50px; }
.slider-related .arrow-left { right: 62px; }
.slider-related .is-video:before { position: absolute; content: ''; top: 0; left: 0; height: 100%; width: 100%; transition: all 0.3s;  }
.slider-related .is-video:after { opacity: 0; position: absolute; content: ''; top: 100px; left: 130px; border: solid transparent; border-width: 15px 30px; border-color: transparent transparent transparent #fff; filter: drop-shadow(0 0 2px #000); transition: all 0.3s;}
.slider-related .is-video:hover:before { background-color: rgba(0, 0, 0, 0.4);}
.slider-related .is-video:hover:after { opacity: 1; }

.slider-gallery { position: relative; overflow: hidden; width: 353px; height: 467px; }
.slider-gallery-thumbs { overflow: hidden; width: 353px; height: 114px; }
.slider-gallery-thumbs .slick-slide img { width: auto; }

/* ------------------------------------------------------------ *
    #boxes
* ------------------------------------------------------------ */
.boxes { overflow: hidden; margin: 0 -10px; font-size: 0; }
.box { position: relative; margin: 0 10px 20px; box-sizing: border-box; display: inline-block; vertical-align: top; font-size: var(--font-size-primary); }
.box-basic  { width: 285px; border: 1px solid var(--color-neutral-md); }
.box-basic .picture { position: relative; display: block; }
.box-basic,
.box-basic.highlight .picture { height: 482px; }
.box-basic .picture img { width: 100%; height: auto; }
.box-basic .picture .label { position: absolute; bottom: 0; left: 0; display: block; min-width: 165px; padding: 9px; background: #48c4d6; font-size: 14px; line-height: 18px; color: #fff; text-align: center; text-transform: capitalize; }
.box-basic .picture .label-status-active { background: var(--color-accent); }
.box-basic .picture .label-status-inactive { background: var(--color-neutral-dk); }
.box-basic .content { position: relative; padding: 15px 22px 61px; }
.box-basic .content .title { overflow: hidden; max-height: 78px; margin-bottom: 15px; font-size: 18px; line-height: 24px; color: var(--color-primary); text-transform: inherit; }
.box-basic .content .title a { color: inherit; }
.box-basic .content .rich-text { overflow: hidden; height: 90px; margin-bottom: 30px; font-size: 14px; line-height: 18px; }
.box-basic .utils { position: absolute; right: 20px; bottom: 15px; left: 20px; }
.box-basic .btns { float: left; }
.box-basic .date { float: right; margin: 15px 0; }
.box-basic.highlight { width: 590px; }
.box-basic.highlight .picture img { height: 100%; }
.box-basic.highlight .picture .label { display: none; }
.box-basic.highlight .content { position: absolute; bottom: -2px; left: 0; border: 0; background: linear-gradient(to bottom, transparent, #000); }
.box-basic.highlight .content .title { color: #fff; }
.box-basic.highlight .content .rich-text { display: none; }

.box-features { width: 400px; margin: 0; padding: 0 20px; }
.box-features .picture .icon-svg { width: 66px; height: 66px; display: block; margin: 0 auto; fill: var(--color-primary); }
.box-features .title { max-width: 250px; height: 63px; overflow: hidden; margin: 0 auto; padding: 30px; text-align: center; text-transform: uppercase; font-weight: normal; line-height: 21px; }
.box-features .rich-text { font-size: 14px; line-height: 18px; text-align: center; }
.box-features .btns { text-align: center; }
.box-features .btn-link .icon-svg { width: 20px; height: 20px; display: inline-block; vertical-align: text-top; fill: var(--color-primary); }
.box-features .btn-link { padding-top: 20px; }

.box-events { background: #fff; }

.box-events.expired .picture .calendar .event-top-calendar { background: #e5e5e5; }
.box-events.expired .picture .calendar .event-bottom-calendar { background: var(--color-neutral-dk); }

.box-events .picture .calendar { position: absolute; bottom: -20px; left: 0; min-width: 68px; height: 81px; margin-left: 15px; display: block; background: #48c4d6; font-size: 14px; line-height: 18px; color: #fff; text-align: center; z-index: 1; }
.box-events .calendar .event-top-calendar { padding: 21px 0; background: #b1f1ff; color: var(--color-text); font-size: 21px; font-weight: bold; }
.box-events .calendar .event-bottom-calendar { padding: 2px; font-size: 14px; text-transform: lowercase; }
.box-events .content { padding: 30px 22px 80px; }
.box-events .content .rich-text { max-height: 54px; } 
.box-events .date { color: var(--color-neutral-dk); }
.box-events .date .icon-svg { fill: var(--color-neutral-dk); }

.boxes-featured-small { padding: 0 30px; }
.box-featured-small { width: 270px; height: auto; border: 0; }
.box-featured-small .picture { background: var(--color-primary); transition: .3s ease-in-out; }
.box-featured-small .box-content img { opacity: 1;  }
.box-featured-small .box-content:hover img { opacity: 0.6; }

.box-featured-small .content { position: absolute; bottom: 0; left: 50%; padding: 10px 0; transform: translate(-50%,0);
 transition: .5s ease-in-out; }
.box-featured-small .box-content:hover .content { bottom: 50%; transform: translate(-50%, 50%); }
.box-featured-small .content:before { content: ""; position: absolute; width: 100%; height: 1px; bottom: 0; left: 0; background-color: rgba(255, 255, 255, 0.3); visibility: hidden; transform: scaleX(0); transition: all .6s ease-in-out 0s; }
.box-featured-small .box-content:hover .content:before { visibility: visible; transform: scaleX(1); }
.box-featured-small .content .title { position: relative; font-size: 26px; text-align: center; text-transform: uppercase; color: #fff; text-shadow: 1px 1px 4px #050708; }

.box-basic.box-featured { width: 590px; }
.box-featured .picture { background: var(--color-primary); transition: .3s ease-in-out;  }
.box-featured img { opacity: 1;  }
.box-featured:hover img { opacity: 0.6; }

.box-featured .content { position: absolute; bottom: -15px; left: 0; margin: 0 35px; padding: 10px 0; transition:.8s ease-in-out 0s; }
.box-featured:hover .content { bottom: 50%; transform: translate(0, 50%); }
.box-featured:hover .content:before { visibility: visible; transform: scaleX(1); }

.box-featured .content .title { position: relative; max-height: 100%; font-size: 48px; line-height: 1; text-transform: uppercase; color: #fff; }
.box-featured .content .rich-text { opacity: 0; visibility: hidden; margin-bottom: 0; font-size: 22px; color: #fff; line-height: 24px; transition: all .8s ease-in-out 0s; }
.box-featured:hover .rich-text { opacity: 1; visibility: visible; }
.box-featured .btns { position: absolute; bottom: 30px; left: 0; float: none; margin-left: 35px; }

.box-light { width: 590px; border: 1px solid var(--color-neutral-md); color: var(--color-text); }
.box-light .content { padding: 20px; border-top: 1px solid var(--color-neutral-lt); background: #f1f1f1; font-size: 14px; }
.box-light .picture { cursor: default; }
.box-light .title { height: 40px; overflow: hidden; margin-bottom: 10px; color: var(--color-primary); text-align: center; line-height: 20px; }
.box-light .rich-text { height: 90px; overflow: hidden; font-size: 14px; line-height: 18px; }

.box-file { width: 285px; height: 225px; background: var(--color-neutral-lt); border: 1px solid var(--color-neutral-md); font-size: 19px;  font-weight: bold; }
.box-file .title { height: 96px; overflow: hidden; margin: 20px; color: var(--color-primary); line-height: 24px; }
.box-file .utils { padding: 20px; }
.box-file strong { float: right; display: inline-block; vertical-align: middle; color: var(--color-text); font-size: 30px; letter-spacing: -2px; }

.box-product { width: 285px; border: 1px solid var(--color-neutral-md); }
.box-product .picture { position: relative; display: block; border-bottom: 1px solid var(--color-neutral-md); }
.box-product .picture .label { position: absolute; bottom: 0; left: 0; display: block; min-width: 165px; padding: 9px; background: var(--color-neutral-dk); font-size: 14px; line-height: 18px; color: #fff; text-align: center; text-transform: capitalize; }
.box-product .content { position: relative; max-height: 100%; height: 180px; padding: 15px 20px 60px; background: var(--color-neutral-lt);  }
.box-product .content .title { overflow: hidden; max-height: 78px; margin-bottom: 15px; font-size: 21px; line-height: 26px; color: var(--color-primary); text-transform: uppercase; }
.box-product .content .rich-text { overflow: hidden; height: 90px; margin-bottom: 30px; font-size: 14px; line-height: 18px; }
.box-product .date { float: none; display: block; padding-bottom: 20px; color: var(--color-neutral-dk); font-size: 15px; }
.box-product .utils { position: absolute; right: 20px; bottom: 15px; left: 20px; }
.box-product .price { color: var(--color-primary); font-size: 18px; font-weight: bold; }

.box-underline { display: block; margin: 0 10px; padding: 20px 0; border-bottom: 1px solid var(--color-neutral-md); }
.box-underline .category { padding-bottom: 10px; }
.box-underline .title { padding-bottom: 5px; color: var(--color-primary); font-size: 18px; }
.box-underline a { display: block; padding-bottom: 5px; }
.box-underline .author { display: block; padding-bottom: 10px; font-size: 14px; color: #535353; font-weight: 400; }
.box-underline .author:before { content: '-'; display: inline-block; padding-right: 5px; }

.box-simple { width: 285px; border: 1px solid var(--color-neutral-md); }
.box-simple .picture { border-bottom: 1px solid var(--color-neutral-md); }
.box-simple .content { padding: 20px; text-align: center; }

.box-media { width: 285px; }
.box-media .picture { position: relative; display: block; margin-bottom: 10px; }
.box-media .picture .label { position: absolute; bottom: 0; left: 0; display: block; min-width: 165px; padding: 9px; background: #48c4d6; font-size: 14px; line-height: 18px; color: #fff; text-align: center; text-transform: capitalize; }
.box-media .content .title { overflow: hidden; max-height: 78px; margin-bottom: 15px; font-size: 21px; line-height: 26px; color: var(--color-primary); text-transform: inherit; }

/* ------------------------------------------------------------ *
    #accents
* ------------------------------------------------------------ */
.accents { overflow: hidden; }
.accent { padding: 45px 0; }
.accents .accent-header { overflow: hidden; }
.accents .accent-title { padding-bottom: 25px; font-size: 35px; font-weight: normal; }
.accents .accent-header .accent-title { float: left; }
.accents .accent-header .btns { float: left; overflow: hidden; margin-left: 18px; }

.accent-slider { position: relative; overflow: hidden; height: 580px; padding: 0; }

.accent-features .accent-title { width: 100%; text-align: center; }

.accent-featured { width: 100%; max-height: 100%; background-image: url('../../images/feautered-img.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center; }
.accent-featured .feautered-content { text-align: center; color: #fff; }
.accent-featured .feautered-title { padding-bottom: 10px; font-size: 41px; letter-spacing: 2px; }
.accent-featured .rich-text { max-width: 600px; margin: 0 auto 30px; font-size: 22px; }

.accent-video .video-wrap { width: 820px; height: 490px; float: left; overflow: hidden; }
.accent-video .video-wrap iframe { width: 100%; height: 485px; }
.accent-video .video-list {  height: 490px; overflow: auto; }
.accent-video .video-list::-webkit-scrollbar { width: 6px; }
.accent-video .video-list .list { overflow: hidden; margin-left: 30px; margin-bottom: 5px; padding: 10px; transition: all 0.3s; cursor: pointer; }
.accent-video .video-list .list:hover { background: var(--color-neutral-lt); box-shadow: 1px 1px 5px 0px rgba(230,230,230,1); }
.accent-video ul .btns { float: left; }
.accent-video ul li:hover .btn-circle,
.accent-video ul .selected .btn-circle { background: #fff; }
.accent-video ul li:hover .btn-circle .icon-svg { fill: var(--color-primary); }
.accent-video ul .selected { background: var(--color-neutral-lt); box-shadow: 1px 1px 5px 0px rgba(230,230,230,1); }
.accent-video ul .list-content { max-width: 250px; max-height: 40px; float: left; overflow: hidden; margin: 5px 10px; }

.accents-inner { overflow: hidden; }
.accents-inner .accent { padding-top: 0; }

.accent-related { padding-top: 0; }
.accent-related .box-media { margin-bottom: 0; }

/* Landing page accents */
.accents-campaigns { padding: 0; }
.accent-campaigns { padding: 35px 0; }
.accent-campaigns:last-child { margin-bottom: 0; padding-bottom: 0; }
.accent-campaigns .title { padding-bottom: 20px; color: #000; font-size: 35px; text-transform: uppercase; text-align: center; }
.accent-campaigns .petition_first_col {
	margin-bottom: 25px;
}

.accent-quote .quote { text-align: center; }
.accent-quote .quote-text { max-width: 380px; display: inline-block; vertical-align: top; margin-left: 10px; color: #000; font-size: 24px; font-weight: bold; line-height: 26px; font-style: italic; text-align: left; }
.accent-quote .icon-svg { width: 100px; height: 75px; }
.accent-quote .author { display: block; font-size: 14px; color: var(--color-text); font-weight: normal; line-height: 16px; }

.single-quote .quote { padding: 60px 0; background-repeat: no-repeat; background-position: center; background-size: cover; }
.single-quote .quote .quote-text,
.single-quote .quote .author { color: #fff; }
.single-quote .quote .icon-svg { fill: #fff; }

.accent-quote-text .quote { padding: 65px 45px; background: var(--color-neutral-lt); text-align: left; }
.accent .quote .author:before { content: '-'; display: inline-block; padding-right: 5px; }

.accent-featured-image .content { margin-left: 20px; }
.accent-featured-image .title-primary { display: inline-block; margin-bottom: 40px; padding: 15px; background: #242424; color: #fff; font-size: 40px; }
.accent-featured-image .utils { padding-bottom: 20px; font-size: 22px; }
.accent-featured-image .utils-label,
.accent-featured-image .date { display: inline-block; vertical-align: middle; }
.accent-featured-image .date { padding-left: 20px; color: var(--color-text); }
.accent-featured-image .utils-label .title { font-size: 22px; text-transform: inherit; }
.accent-featured-image .date .icon-svg { fill: var(--color-text); }
.accent-featured-image-news { padding-top: 0; }
.accent-featured-image-news .picture { margin-bottom: -40px; }

.accent-pad .picture { padding-bottom: 30px; }
.accent-pad .content-pad { position: relative; margin-top: -200px; margin-left: 20px; padding: 50px; background: #242424; color: #fff }
.accent-pad .content-pad .title { color: #fff; }
.accent-pad .btns { padding-top: 20px; }

.accent-campaigns.accent-featured { max-height: 100%; padding: 30px 0; background-image: none; }
.accent-campaigns.accent-featured .content { max-height: 100%; padding: 60px 20px; background-repeat: no-repeat; background-size: cover; background-position: center center; text-align: center; }
.accent-campaigns.accent-featured .title { color: #fff; font-size: 44px; }
.accent-campaigns.accent-featured .subtitle { max-width: 800px; margin: 0 auto;  padding-bottom: 20px; color: #fff; font-size: 15px; }

.accent-image-and-text .content-pad { margin: 0; background: #242424; }
.accent-image-and-text .content { padding: 50px; color: #fff; }
.accent-image-and-text .title { color: #fff; }
.accent-image-and-text .article { max-height: 170px; overflow: hidden; line-height: 18px; }
.accent-image-and-text .btns { padding-top: 15px; }

.accent-image-quote .quote { padding: 20px 0; background: none; text-align: left; }

.accent-video-camp .title,
.accent-video-camp .rich-text { max-width: 750px; margin: 0 auto; text-align: center; }
.accent-video-camp .video-wrap { padding-top: 20px; }
.accent-video-camp iframe { width: 100%; height: 800px; }

.accent-images-text { margin: 35px 0; padding: 35px 0 15px; }
.accent-images-text .picture { padding-bottom: 20px; }

.accent-slider-camp .slider-landing { position: relative; }
.accent-slider-camp .slide { width: 100%; height: 675px; margin: 0; background-position: center; background-repeat: no-repeat; background-size: cover; }
.accent-slider-camp .slide .article { position: absolute; bottom: 15px; left: auto; margin: 0 70px; padding: 20px 20px 0; background: rgba(0,0,0, 0.6); color: #fff; text-align: center; }

.accent-slider-camp .slider-landing .arrows { position: absolute; top: 50%; width: 48px; transform: translate3d(0, -50%, 0); z-index: 1; }
.accent-slider-camp .slider-landing .arrow-right-land { right: 20px; }
.accent-slider-camp .slider-landing .arrow-left-land { left: 20px; }

.accent-files { margin:35px 0; padding: 35px 0; }
.accent-files .widget-list li { width: 296px; display: inline-block; padding-bottom: 20px; }
.accent-files .widget-list li .title { padding: 0; }

.accent-camp-related { padding: 15px 0; }
.accent-camp-related .accent-related { padding: 0; }
.accent-camp-related .accent-title { color: #000; font-weight: bold; }
.accent-camp-related .title { padding-bottom: 0; }

/* ------------------------------------------------------------ *
    #blocks
* ------------------------------------------------------------ */ 
.block { overflow: hidden; }
.block-aside { width: 355px; float: left; margin-right: 30px; padding-bottom: 45px; }
.block-picture { position: relative; }
.block-picture .label { position: absolute; bottom: 0; left: 0; display: block; min-width: 165px; padding: 9px; background: #48c4d6; font-size: 14px; line-height: 18px; color: #fff; text-align: center; text-transform: capitalize; }
.block-aside .map-link { display: block; }
.block-content { width: 800px; float: left; overflow: hidden; padding-bottom: 40px; }
.block-content .article .heading { line-height: 1;}
.block-title { padding-bottom: 20px; font-size: 35px; font-weight: normal; }

/* block timeline */
.timeline { position: relative; overflow: hidden; padding-top: 110px; }
.timeline:before { content: ''; position: absolute; top: 0; left: 50%; width: 10px; height: 100%; margin: 0 auto; background-image: url('../../images/history-line.png'); z-index: -1; transform: translate3d(-50%, 0, 0); }
.timeline-item { overflow: hidden; padding-bottom: 40px; }
.timeline-item .picture { width: calc(50% - 80px); float: left; }
.timeline-item .article { width: calc(50% - 80px); float: right; }
.timeline-item:nth-child(even) .picture { float: right; }
.timeline-item:nth-child(even) .article { float: left; }
.timeline-item .title { position: relative; margin-bottom: 43px; font-size: 72px; text-align: center; font-weight: normal; background: #fff; z-index: 1; }
.timeline-item .title:before { content: ''; position: absolute; right: 0; bottom: 0; width: 56%; border-bottom: 1px solid var(--color-neutral-md); }
.timeline-item:nth-child(even) .title:before { right: auto; left: 0; }

/* block team member */
.team-member { overflow: hidden; padding: 20px 0; }
.team-member .title { padding-bottom: 20px; font-size: 35px; font-weight: normal; text-transform: uppercase; }
.team-member .picture { width: 200px; float: left; margin-right: 20px; }
.team-member .article { width: 895px; float: left; }
.team-member.chairman .article { width: 980px; }
.team-member.chairman { padding: 20px 0; }
.team-member .title { color: var(--color-primary); font-size: 30px; font-weight: normal; text-transform: capitalize; }
.team-member .chairman-title { padding-bottom: 20px; color: var(--color-primary); text-transform: uppercase; }

/* block contact */
.block-contacts .title { padding-bottom: 20px; font-size: 35px; font-weight: normal; text-transform: uppercase; }
.block-contacts .rich-text h6 { height: 48px; overflow: hidden; margin-bottom: 10px; color: var(--color-primary); font-size: 18px; text-transform: uppercase; line-height: 24px; }
.block-contacts .address-list li { padding-bottom: 10px; }
.block-contacts .address-list img { float: left; width: 20px; height: 20px; margin-right: 10px; margin-bottom: 10px; }
.block-contacts p { overflow: hidden; }
.block-contacts .privacy-info { font-size: 15px; font-style: italic; }

.map-contacts { position: relative; width: 100%; height: 500px; }

/* block legal aid */
.block-legal-aid .label { margin-bottom: 10px; font-size: 17px; }

/* block products */
.block-product-details .gallery { border: 1px solid #e4e4e4; border-top: 0; }
.block-product-details .title-primary { font-size: 30px; }
.product-features { padding-bottom: 20px; font-size: 15px; }
.product-features dt { float: left; margin-right: 10px; }
.product-features dd { font-weight: bold; }
.product-features ul li { display: inline-block; vertical-align: top; }
.product-view-actions { padding: 20px 40px; background: var(--color-neutral-md); text-align: center; }
.product-view-actions .price-old { padding-bottom: 10px; }
.product-view-actions .price-new { padding-bottom: 10px; color: var(--color-primary); font-size: 20px; }
.product-view-actions .btn-primary { max-width: 140px; display: block; margin: 0 auto 10px; }
.block-product-details .title { color: var(--color-primary); }

/* block cart */
.block-cart .block-title { text-align: center; }
.block-cart .msg { max-width: 640px; margin: 0 auto; margin-bottom: 40px; }

/* block multimedia*/
.block-multimedia .video-embeded { width: 100%; margin-bottom: 40px; }
.block-multimedia .utils { overflow: hidden;  padding-bottom: 20px; }
.block-multimedia .title { max-width: 70%; float: left; font-size: 22px; color: var(--color-primary); }
.block-multimedia .btns { float: right; }
.block-multimedia .btn-back { margin-right: 10px; border: 1px solid var(--color-neutral-md); }
.block-multimedia .btn-back:hover { border-color: var(--color-primary); }

/* donation */
.block-donation .donation-method { overflow: hidden; padding: 30px 0; }
.block-donation .donation-method .content { float: left; }
.block-donation .donation-method .title { font-size: 22px; text-transform: inherit; }
.block-donation .donation-method .utils { float: right; }
.block-donation .donation-method .content .rich-text { max-width: 400px; }
.block-donation .donation-method .form-donate { width: 500px; float: right; }
.block-donation .donation-method .utils { float: right; }
.block-donation .donation-method .bank-account { font-weight: bold; }

/* ------------------------------------------------------------ *
    #components
* ------------------------------------------------------------ */ 
.component-btns { height: 30px; padding: 30px; background-color: var(--color-neutral-md); }
.component-btns .btn-back { margin-right: 13px; }

/* widget component */
.widgets { padding: 30px 30px 0; background: var(--color-neutral-lt); }
.widgets .widget-title { padding-bottom: 10px; border-bottom: 2px solid var(--color-text); text-transform: uppercase; }
.widgets > div { padding-bottom: 20px; }
.widget-list { padding: 10px 0; }
.widget-list li { padding: 5px 0; } 
.widget-list .title { max-width: 235px; display: inline-block; vertical-align: middle; margin-left: 10px; font-size: 18px; color: var(--color-text); /*text-transform: capitalize;*/ }
.widgets .link-map { display: inline-block; margin-left: 60px; line-height: 0; }

.widget-counter { text-align: center; }
.widget-counter strong { font-size: 43px; }
.widget-counter p { font-size: 22px; }

/* accordion component */
.accordion-title { padding-bottom: 10px; font-size: 24px; }
.accordion-item { overflow: hidden; margin-bottom: 20px; background: var(--color-neutral-lt); border: 1px solid var(--color-neutral-md); }
.accordion-item .accordion-head { display: block; min-height: 46px; padding: 20px 40px; font-size: 30px; color: var(--color-primary); }
.accordion-item .accordion-head .title { display: inline-block; vertical-align: middle; text-transform: uppercase; }
.accordion-item .accordion-head .icon-pad { float: right; transition: all 0.5s; }
.accordion-item .accordion-body { display: none; padding: 0 40px 20px; }
.accordion-item.is-active .accordion-body { display: block; animation: fade-in 1s 1 ease-in-out; }
.accordion-item.is-active .icon-svg { transform: rotate(180deg); }

/* ------------------------------------------------------------ *
    #pagination
* ------------------------------------------------------------ */ 
.paging { margin-top: 25px; text-align: center; background: var(--color-neutral-lt); }
.paging ul li { display: inline-block;  vertical-align: top; padding: 0 10px; color: var(--color-primary); font-size: var(--font-size-primary); font-weight: bold; line-height: 45px; transition: all .3s; }
.paging ul li a { color: var(--color-primary); }
.paging ul li.selected { padding: 0 13px; background: var(--color-primary); }
.paging ul li.selected a { color: #fff; }
.paging .prev { float: left; }
.paging .prev a {  color: var(--color-neutral-dk); }
.paging .next { float: right; }
.paging .next,
.paging .prev { position: relative; padding: 0 35px; }
.paging .next:after,
.paging .prev:after { content: ''; position: absolute; top: 0; right: 15px; z-index: 2; display: block; margin: 17px 0; padding: 3px; border: solid var(--color-primary); border-width: 0 3px 3px 0; border-radius: 2px; pointer-events: none; transform: rotate(-45deg); transition: all 0.3s; }
.paging .prev:after { right: auto; left: 15px; border-color: var(--color-neutral-dk); transform: rotate(135deg); }

/* ------------------------------------------------------------ *
    #toolbars
* ------------------------------------------------------------ */ 
.toolbar .date { padding-bottom: 10px; color: var(--color-neutral-dk); }
.toolbar .date .icon-svg { fill: var(--color-neutral-dk); }
.toolbar .date .icon-pad { padding: 4px; background: var(--color-neutral-md); }
.toolbar .date p { padding-top: 7px; }
.toolbar-authors .authors { margin-bottom: 40px; padding: 20px; background: var(--color-neutral-md); }

.toolbar-filter { padding-bottom: 20px; }
.toolbar-filter:after { @apply --clearfix;}
.toolbar-filter .title { float: left; margin-right: 15px; }
.toolbar-filter .filter { position: relative; float: left; margin-right: 10px; margin-bottom: 10px; padding: 2px 20px; padding-right: 30px; border-radius: 50px; background: #f3f3f3;}
.toolbar-filter .filter a { color: #777777; }
.toolbar-filter .filter:after { content: ''; position: absolute; top: 50%; right: 10px; width: 10px; height: 10px; background-image: url('../../images/x-icon.svg'); background-size: 10px; transform: translate3d(0, -50%, 0); }
.toolbar-filter .btns { float: left; margin-top: 5px; margin-left: 10px; }
.toolbar-filter .btn-link { padding: 0; }

.block-cart .bank-info { display: none; }
.block-cart .show-bank-info {display: block; margin-top: -40px; }

.js-hidden-input { display: none; }
.show-input { display: block; }

/* ------------------------------------------------------------ *
    #404
* ------------------------------------------------------------ */ 
.page-not-found { padding: 120px 0; text-align: center; }
.page-not-found figure { padding-bottom: 40px; }
.page-not-found img { max-width: 400px; }
.page-not-found .rich-text { max-width: 400px; margin: 0 auto; padding-top: 40px; border-top: 2px solid var(--color-neutral-md); }
