:root {
	--clearfix: {
		content: ''; 
		display: block; 
		clear: both; 
	};

	--font-primary:          'universum';
	--font-size-primary:     17px;
	--font-weight-primary:   normal;

	--success:                  #19cd15;
	--error:                    #ed1620;
	--info:                     #15b6cd;
	--warning:                  #ff7200;

	--color-primary:            #15b6cd; /* blue */
	--color-secondary:          #050708; /* light black */
	--color-accent:           	#ff7200; /* orange */
	--color-neutral-lt:         #f8f8f8;
	--color-neutral-md:         #ebebeb;
	--color-neutral-dk:         #a2a2a2;

	--color-text:               #535353;
	--color-link:               #15b6cd;

	@custom-media --breakpoint-desktop-big (max-width: 1440px);
	@custom-media --breakpoint-desktop (max-width: 1260px);
	@custom-media --breakpoint-tablet (max-width: 991px);
	@custom-media --breakpoint-tablet-sm (max-width: 767px);
	@custom-media --breakpoint-mobile (max-width: 479px);
	@custom-media --breakpoint-mobile-sm (max-width: 320px);
}