/* ------------------------------------------------------------ *\
	#containers
\* ------------------------------------------------------------ */
.container { position: relative; min-height: 100vh; width: 100%; overflow: hidden; }
.wrapper { width: 1200px; height: 100%; margin: 9px auto; }
.main { overflow: hidden; width: 100%; margin-top: 5px; }  /* margin top for the fixed header */

/* ------------------------------------------------------------ *
    #header
* ------------------------------------------------------------ */
.header { height: 90px; background: #fff; border-bottom: 1px solid var(--color-neutral-md); }
.header:after { @apply --clearfix; }
.header .logo { width: 170px; height: 37px;  float: left; margin: 30px 25px 24px 0; }

.header .header-btns { float: right; margin: 30px 0; }
.header .btns-heighlight { overflow: hidden; float: left; }
.header .btns-secondary { float: left; overflow: hidden; margin-left: 20px; }
.header .header-btns .btns { width: 30px; height: 30px; float: right; margin-left: 40px; margin-right: 20px; border-radius: 50px; }
.header .header-btns .btn-pad:hover,
.header .header-btns .btn-pad.is-active { background: var(--color-primary); }
.header .btn-menu-wrap { float: right;  background: var(--color-primary); }

.header-wrapper { position: fixed; top: 0; background-color: #fff; width: 100%; }
/* ------------------------------------------------------------ *
    #footer
* ------------------------------------------------------------ */
.footer { background: var(--color-secondary); }
.footer-top { background-repeat: no-repeat; background-size: cover; }
.footer-top .footer-top-content { display: flex; }
.footer-top .rich-text { margin: 24px 0; color: #fff; font-size: 18px; font-weight: normal; }
.footer-top .btn-accent { height: 43px; margin: 14px 0 14px 92px; border: 1px solid #fff; background: transparent; font-size: 25px; line-height: 43px; }
.footer-top .btn-accent:hover { background: var(--color-primary); border-color: var(--color-primary); }
.footer-middle { padding: 45px 0; }
.footer-title { position: relative; margin-bottom: 30px; color: var(--color-primary); font-size: 18px; text-transform: uppercase; }
.footer-title:after { content: ''; position: absolute; left: 0; bottom: -15px; width: 60px; border-bottom: 1px solid var(--color-primary); opacity: 0.8; }
.footer-middle li { margin-bottom: 10px; }
.footer-middle li a { color: #fff; /*text-transform: capitalize;*/ }
.footer-middle li a:hover { color: var(--color-accent); }
.newsletter-footer .note { margin-bottom: 20px; font-size: 16px; color: var(--color-neutral-dk); }
.newsletter-footer .form { position: relative; }
.newsletter-footer .form input { height: 35px; margin-bottom: 20px; padding: 0 15px;  }
.socials-footer li { float: left; }

.footer-bottom { width: 100%; height: 50px; background: #151b1e; }
.footer-bottom-content { padding: 20px 0; font-size: 14px; }
.copy { float: left; color: var(--color-neutral-dk); }
.copy a { color: #fff; }
.trademark { float: right; }
.trademark img { width: 106px; }

/* ------------------------------------------------------------ *
    #main
* ------------------------------------------------------------ */ 
.main-head { padding-bottom: 30px; }
.main-head .breadcrumbs ul,
.main-head .title-primary { text-align: center; }
.main-head .rich-text { padding-bottom: 45px; font-size: 17px; text-align: center; line-height: 19px; }

.main-body { overflow: hidden; padding-bottom: 45px; }
.main-body.full { width: 100%; }
.main-body .sidebar { width: 275px; float: left; margin-right: 30px; }
.main-body .main-content { width: 895px; float: left; overflow: hidden; }
