/* ------------------------------------------------------------ *
	#reset
* ------------------------------------------------------------ */
* { margin: 0; padding: 0; outline: 0; }

*:before,
*:after { box-sizing: border-box; }

*::-moz-focus-inner { border: 0; padding: 0; }

*::selection { background: var(--color-primary); color: #fff; }

html { min-height: 100vh; width: 100%; font-size: var(--font-size-primary); tap-highlight-color: rgba(0, 0, 0, 0); }
body { min-height: 100vh; width: 100%; background: #ffffff; font-family: var(--font-primary); font-size: var(--font-size-primary); line-height: 1.3; color: var(--color-text); -moz-osx-font-smoothing: grayscale; font-smoothing: antialiased; text-size-adjust: none; }

a { color: var(--color-link); text-decoration: none; cursor: pointer; transition: all 0.3s; }
a:hover { text-decoration: none; }

ul { list-style-type: none; }

img { width: 100%; }
img,
iframe,
video,
audio,
object { max-width: 100%; }

article,
aside,
details,
figcaption,
figure,
picture,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary { display: block; }

img,
iframe { border: 0 none; }

img { height: auto; display: inline-block; vertical-align: top; transition: all 0.3s; user-select: none; }

table { border-collapse: collapse; border-spacing: 0; }

label:hover,
input[type="radio"]:hover,
input[type="checkbox"]:hover { cursor: pointer; }
textarea { overflow: auto; resize: none; overflow-scrolling: touch; }

button,
input,
select,
textarea { font-family: var(--font-primary); transition: all 0.3s; }

small { font-size: 80%; }
sub,
sup { position: relative; /*vertical-align: baseline;*/ font-size: 75%; line-height: 0; }

h1,
h2,
h3,
h4,
h5,
h6 { font-family: var(--font-primary); }

h1 { font-size: 35px; text-transform: uppercase; }
h2 { font-size: 24px; text-transform: uppercase; }
h3 { font-size: 22px; }
h4 { font-size: 20px; }
h5 { font-size: 18px; }
h6 { font-size: 16px; }

/* ------------------------------------------------------------ *\
	# fonts
\* ------------------------------------------------------------ */
@font-face {
	font-family: 'universum';
	src: url('../../fonts/universum_bg_regular-webfont.eot');
	src: url('../../fonts/universum_bg_regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../../fonts/universum_bg_regular-webfont.woff2') format('woff2'),
		 url('../../fonts/universum_bg_regular-webfont.woff') format('woff'),
		 url('../../fonts/universum_bg_regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'universum';
	src: url('../../fonts/universum_bg_ita-webfont.eot');
	src: url('../../fonts/universum_bg_ita-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../../fonts/universum_bg_ita-webfont.woff2') format('woff2'),
		 url('../../fonts/universum_bg_ita-webfont.woff') format('woff'),
		 url('../../fonts/universum_bg_ita-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'universum';
	src: url('../../fonts/universum_bg_bold-webfont.eot');
	src: url('../../fonts/universum_bg_bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../../fonts/universum_bg_bold-webfont.woff2') format('woff2'),
		 url('../../fonts/universum_bg_bold-webfont.woff') format('woff'),
		 url('../../fonts/universum_bg_bold-webfont.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'universum';
	src: url('../../fonts/universum_bg_boldita-webfont.eot');
	src: url('../../fonts/universum_bg_boldita-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../../fonts/universum_bg_boldita-webfont.woff2') format('woff2'),
		 url('../../fonts/universum_bg_boldita-webfont.woff') format('woff'),
		 url('../../fonts/universum_bg_boldita-webfont.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

/* ------------------------------------------------------------ *
	#general
* ------------------------------------------------------------ */
.clearfix:after { content: ' '; display: block; clear: both; }

.logo { display: block; user-select: none; }
.logo img { max-width: 100%; height: auto; /*image-rendering: pixelated;*/ }

.map-tooltip { overflow: hidden; font-size: 100%; line-height: 1.35; white-space: nowrap; }

/* z-index hierarchy */
.header,
.footer,
.container,
.accents { position: relative; width: 100%; }

.header { z-index: 40; }
.footer { z-index: 30; }
.container { z-index: 20; }
.accents { z-index: 10; }

hr { display: block; border: none; width: 100%; height: 1px; margin: 10px 0; background: var(--color-neutral-md); }

.authors { font-size: 16px; line-height: 1.2; }
.authors .label { float: left; margin-right: 10px; font-weight: bold; }
.authors ul li {display: inline-block; vertical-align: top; }

.table-tags li { display: inline-block; vertical-align: top; margin-top: 20px; padding: 3px 20px; background: #f0f0f0; color: #777777; border-radius: 50px; }

.heighlight { background: var(--color-neutral-lt); }
.message { font-size: 12px; color: var(--color-text); }

.video-embeded { position: relative; padding-bottom: 56.23%; }
.video-embeded iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.related-target { display: none; }
.has-flag { display: block; }

::-webkit-scrollbar-track { background: rgba(21, 182, 205, 0.3); background-color: var(--color-neutral-md); }
::-webkit-scrollbar { width: 17px; background-color: var(--color-neutral-md); }
::-webkit-scrollbar-thumb { background-color: var(--color-neutral-dk); opacity: 0.8; }


/* ------------------------------------------------------------ *
    #messages
* ------------------------------------------------------------ */
.msg { padding: 20px; margin: 5px 0 25px; background-color: #555555; background-repeat: no-repeat; background-position: 20px 20px; font-size: var(--font-size-primary); line-height: 1.3; color: #fff; }
.msg .title { margin-bottom: 10px; font-size: 18px; font-weight: bold; }

.msg-success,
.msg-error,
.msg-info,
.msg-warning { padding-left: 60px; }
.msg-success { background-color: var(--success); background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") }
.msg-error { background-color: var(--error); background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") }
.msg-info { background-color: var(--info); background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") }
.msg-warning { background-color: var(--warning); background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") }
.msg-light { background-color: var(--color-neutral-md); color: var(--color-text); }

p.msg { padding: 10px; background-image: none; }

/* ------------------------------------------------------------ *\
	#notifications
\* ------------------------------------------------------------ */
.toast-success { background-color: var(--success); }
.toast-error { background-color: var(--error); }
.toast-info { background-color: var(--info); }
.toast-warning { background-color: var(--warning); }

#toast-container > div { box-shadow: none; opacity: 1; }
#toast-container > div:hover { box-shadow: none; opacity: .9; }
.toast-progress { opacity: .6; height: 3px; background-color: #fff; }

/* ------------------------------------------------------------ *
	#animations
* ------------------------------------------------------------ */
@keyframes fade-in {
	0% {
		opacity: 0;
		visibility: hidden; 
	}
	100% {
		opacity: 1;
		visibility: visible; 
	}
}

@keyframes up-and-down {
	0% {
		bottom: 19px;
	}
	50% {
		bottom: 10px;
	}
	100% {
		bottom: 19px;
	}
}

/* ------------------------------------------------------------ *
	#grid
* ------------------------------------------------------------ */
.cols:before,
.cols:after { content: " "; display: table; clear: both; }
.cols,
.col { box-sizing: border-box; }

.cols { margin-right: -15px; margin-left: -15px; padding-right: 15px; padding-left: 15px; }
.col { min-height: 10px; float: left; padding: 0 15px; }
.cols-full { padding-right: 0; padding-left: 0; }
.cols-no-gutter { margin-right: 0; margin-left: 0; }
.cols-no-gutter .col { padding: 0; }
.cols-full.cols-no-gutter { padding-right: 0; padding-left: 0; }

.col1of12 { width: 8.33333333%; }
.col2of12 { width: 16.66666667%; }
.col3of12 { width: 25%; }
.col4of12 { width: 33.33333333%; }
.col5of12 { width: 41.66666667%; }
.col6of12 { width: 50%; }
.col7of12 { width: 58.33333333%; }
.col8of12 { width: 66.66666667%; }
.col9of12 { width: 75%; }
.col10of12 { width: 83.33333333%; }
.col11of12 { width: 91.66666667%; }
.col12of12 { width: 100%; }

/* ------------------------------------------------------------ *
    #dates
* ------------------------------------------------------------ */
.date { color: var(--color-primary); font-size: 12px; font-weight: bold; line-height: 1; }
.date .icon-svg { width: 16px; height: 16px; padding-right: 2px; fill: var(--color-primary); vertical-align: top; }
.date p { display: inline-block; vertical-align: top; padding-top: 4px;  }
.date .date-list { display: inline-block; vertical-align: top; }
.date .date-list p { display: block; padding-top: 0; text-transform: uppercase; line-height: 1.3; }
/* ------------------------------------------------------------ *
    #banners
* ------------------------------------------------------------ */
.banner-wide { width: 728px; height: 90px; margin: 0 auto; }
.banner-medium { width: 275px; height: 231px; }
.banner-big { width: 1200px; height: 232px; }

/* ------------------------------------------------------------ *
    #titles
* ------------------------------------------------------------ */
.title-primary { font-size: 48px; padding-bottom: 35px; color: var(--color-primary); line-height: 1; text-transform: inherit; }
.title-secondary { padding-bottom: 20px; }

/* ------------------------------------------------------------ *
    #pad
* ------------------------------------------------------------ */
.pad-basic { padding: 20px; background: #fff; border: 1px solid var(--color-neutral-md); }